/*-------------------------------------------------------*/
/* Spacings
/*-------------------------------------------------------*/
.nomargin {	margin: 0!important; }
.nopadding { padding: 0!important; }
.mt-0 { margin-top: 0!important; }
.mt-10 { margin-top: 10px; }
.mt-20 { margin-top: 20px; }
.mt-30 { margin-top: 30px; }
.mt-40 { margin-top: 40px; }
.mt-50 { margin-top: 50px; }
.mt-60 { margin-top: 60px; }
.mt-70 { margin-top: 70px; }
.mt-80 { margin-top: 80px; }
.mt-90 { margin-top: 90px; }
.mt-100 {	margin-top: 100px; }
.mt-110 {	margin-top: 110px; }
.mt-120 {	margin-top: 120px; }
.mt-130 {	margin-top: 130px; }
.mt-140 {	margin-top: 140px; }
.mb-0 {	margin-bottom: 0!important; }
.mb-10 { margin-bottom: 10px; }
.mb-20 { margin-bottom: 20px; }
.mb-30 { margin-bottom: 30px; }
.mb-40 { margin-bottom: 40px; }
.mb-50 { margin-bottom: 50px; }
.mb-60 { margin-bottom: 60px; }
.mb-70 { margin-bottom: 70px; }
.mb-80 { margin-bottom: 80px; }
.mb-90 { margin-bottom: 90px; }
.mb-100 {	margin-bottom: 100px; }
.mb-110 {	margin-bottom: 110px; }
.mb-120 {	margin-bottom: 120px; }
.mb-130 {	margin-bottom: 130px; }
.mb-140 {	margin-bottom: 140px; }
.pt-0 {	padding-top: 0; }
.pt-10 { padding-top: 10px; }
.pt-20 { padding-top: 20px; }
.pt-30 { padding-top: 30px; }
.pt-40 { padding-top: 40px; }
.pt-50 { padding-top: 50px; }
.pt-60 { padding-top: 60px; }
.pt-70 { padding-top: 70px; }
.pt-80 { padding-top: 80px; }
.pt-90 { padding-top: 90px; }
.pt-100 {	padding-top: 100px; }
.pt-110 {	padding-top: 110px; }
.pt-120 {	padding-top: 120px; }
.pt-130 {	padding-top: 130px; }
.pt-140 {	padding-top: 140px; }
.pt-150 {	padding-top: 150px; }
.pt-160 {	padding-top: 160px; }
.pt-170 {	padding-top: 170px; }
.pt-180 {	padding-top: 180px; }
.pt-190 {	padding-top: 190px; }
.pt-200 {	padding-top: 200px; }
.pb-0 {	padding-bottom: 0; }
.pb-10 { padding-bottom: 10px; }
.pb-20 { padding-bottom: 20px; }
.pb-30 { padding-bottom: 30px; }
.pb-40 { padding-bottom: 40px; }
.pb-50 { padding-bottom: 50px; }
.pb-60 { padding-bottom: 60px; }
.pb-70 { padding-bottom: 70px; }
.pb-80 { padding-bottom: 80px; }
.pb-90 { padding-bottom: 90px; }
.pb-100 {	padding-bottom: 100px; }
.pb-110 {	padding-bottom: 110px; }
.pb-120 {	padding-bottom: 120px; }
.pb-130 {	padding-bottom: 130px; }
.pb-140 {	padding-bottom: 140px; }
.pb-150 {	padding-bottom: 150px; }
.pb-160 {	padding-bottom: 160px; }
.pb-170 {	padding-bottom: 170px; }
.pb-180 {	padding-bottom: 180px; }
.pb-190 {	padding-bottom: 190px; }
.pb-200 {	padding-bottom: 200px; }

@media only screen and (max-width:1199px) {
.mt-lrg-0 {	margin-top: 0!important; }
.mt-lrg-10 { margin-top: 10px; }
.mt-lrg-20 { margin-top: 20px; }
.mt-lrg-30 { margin-top: 30px; }
.mt-lrg-40 { margin-top: 40px; }
.mt-lrg-50 { margin-top: 50px; }
.mt-lrg-60 { margin-top: 60px; }
.mt-lrg-70 { margin-top: 70px; }
.mt-lrg-80 { margin-top: 80px; }
.mt-lrg-90 { margin-top: 90px; }
.mt-lrg-100 {	margin-top: 100px; }
.mt-lrg-110 {	margin-top: 110px; }
.mt-lrg-120 {	margin-top: 120px; }
.mt-lrg-130 {	margin-top: 130px; }
.mt-lrg-140 {	margin-top: 140px; }
.mb-lrg-0 {	margin-bottom: 0!important; }
.mb-lrg-10 { margin-bottom: 10px; }
.mb-lrg-20 { margin-bottom: 20px; }
.mb-lrg-30 { margin-bottom: 30px; }
.mb-lrg-40 { margin-bottom: 40px; }
.mb-lrg-50 { margin-bottom: 50px; }
.mb-lrg-60 { margin-bottom: 60px; }
.mb-lrg-70 { margin-bottom: 70px; }
.mb-lrg-80 { margin-bottom: 80px; }
.mb-lrg-90 { margin-bottom: 90px; }
.mb-lrg-100 {	margin-bottom: 100px; }
.mb-lrg-110 {	margin-bottom: 110px; }
.mb-lrg-120 {	margin-bottom: 120px; }
.mb-lrg-130 {	margin-bottom: 130px; }
.mb-lrg-140 {	margin-bottom: 140px; }
.pt-lrg-0 {	padding-top: 0; }
.pt-lrg-10 { padding-top: 10px; }
.pt-lrg-20 { padding-top: 20px; }
.pt-lrg-30 { padding-top: 30px; }
.pt-lrg-40 { padding-top: 40px; }
.pt-lrg-50 { padding-top: 50px; }
.pt-lrg-60 { padding-top: 60px; }
.pt-lrg-70 { padding-top: 70px; }
.pt-lrg-80 { padding-top: 80px; }
.pt-lrg-90 { padding-top: 90px; }
.pt-lrg-100 {	padding-top: 100px; }
.pt-lrg-110 {	padding-top: 110px; }
.pt-lrg-120 {	padding-top: 120px; }
.pt-lrg-130 {	padding-top: 130px; }
.pt-lrg-140 {	padding-top: 140px; }
.pb-lrg-0 {	padding-bottom: 0; }
.pb-lrg-10 { padding-bottom: 10px; }
.pb-lrg-20 { padding-bottom: 20px; }
.pb-lrg-30 { padding-bottom: 30px; }
.pb-lrg-40 { padding-bottom: 40px; }
.pb-lrg-50 { padding-bottom: 50px; }
.pb-lrg-60 { padding-bottom: 60px; }
.pb-lrg-70 { padding-bottom: 70px; }
.pb-lrg-80 { padding-bottom: 80px; }
.pb-lrg-90 { padding-bottom: 90px; }
.pb-lrg-100 {	padding-bottom: 100px; }
.pb-lrg-110 {	padding-bottom: 110px; }
.pb-lrg-120 {	padding-bottom: 120px; }
.pb-lrg-130 {	padding-bottom: 130px; }
.pb-lrg-140 {	padding-bottom: 140px; }
}

@media only screen and (max-width:991px) {
.mt-mdm-0 { margin-top: 0!important; }
.mt-mdm-10 { margin-top: 10px; }
.mt-mdm-20 { margin-top: 20px; }
.mt-mdm-30 { margin-top: 30px; }
.mt-mdm-40 { margin-top: 40px; }
.mt-mdm-50 { margin-top: 50px; }
.mt-mdm-60 { margin-top: 60px; }
.mt-mdm-70 { margin-top: 70px; }
.mt-mdm-80 { margin-top: 80px; }
.mt-mdm-90 { margin-top: 90px; }
.mt-mdm-100 { margin-top: 100px; }
.mt-mdm-110 { margin-top: 110px; }
.mt-mdm-120 { margin-top: 120px; }
.mt-mdm-130 { margin-top: 130px; }
.mt-mdm-140 { margin-top: 140px; }
.mb-mdm-0 { margin-bottom: 0!important; }
.mb-mdm-10 { margin-bottom: 10px; }
.mb-mdm-20 { margin-bottom: 20px; }
.mb-mdm-30 { margin-bottom: 30px; }
.mb-mdm-40 { margin-bottom: 40px; }
.mb-mdm-50 { margin-bottom: 50px; }
.mb-mdm-60 { margin-bottom: 60px; }
.mb-mdm-70 { margin-bottom: 70px; }
.mb-mdm-80 { margin-bottom: 80px; }
.mb-mdm-90 { margin-bottom: 90px; }
.mb-mdm-100 { margin-bottom: 100px; }
.mb-mdm-110 { margin-bottom: 110px; }
.mb-mdm-120 { margin-bottom: 120px; }
.mb-mdm-130 { margin-bottom: 130px; }
.mb-mdm-140 { margin-bottom: 140px; }
.pt-mdm-0 { padding-top: 0; }
.pt-mdm-10 { padding-top: 10px; }
.pt-mdm-20 { padding-top: 20px; }
.pt-mdm-30 { padding-top: 30px; }
.pt-mdm-40 { padding-top: 40px; }
.pt-mdm-50 { padding-top: 50px; }
.pt-mdm-60 { padding-top: 60px; }
.pt-mdm-70 { padding-top: 70px; }
.pt-mdm-80 { padding-top: 80px; }
.pt-mdm-90 { padding-top: 90px; }
.pt-mdm-100 { padding-top: 100px; }
.pt-mdm-110 { padding-top: 110px; }
.pt-mdm-120 { padding-top: 120px; }
.pt-mdm-130 { padding-top: 130px; }
.pt-mdm-140 { padding-top: 140px; }
.pt-mdm-150 { padding-top: 150px; }
.pb-mdm-0 { padding-bottom: 0; }
.pb-mdm-10 { padding-bottom: 10px; }
.pb-mdm-20 { padding-bottom: 20px; }
.pb-mdm-30 { padding-bottom: 30px; }
.pb-mdm-40 { padding-bottom: 40px; }
.pb-mdm-50 { padding-bottom: 50px; }
.pb-mdm-60 { padding-bottom: 60px; }
.pb-mdm-70 { padding-bottom: 70px; }
.pb-mdm-80 { padding-bottom: 80px; }
.pb-mdm-90 { padding-bottom: 90px; }
.pb-mdm-100 { padding-bottom: 100px; }
.pb-mdm-110 { padding-bottom: 110px; }
.pb-mdm-120 { padding-bottom: 120px; }
.pb-mdm-130 { padding-bottom: 130px; }
.pb-mdm-140 { padding-bottom: 140px; }
.pb-mdm-150 { padding-bottom: 150px; }
}

@media only screen and (max-width:767px) {
.mt-sml-0 { margin-top: 0!important; }
.mt-sml-10 { margin-top: 10px; }
.mt-sml-20 { margin-top: 20px; }
.mt-sml-30 { margin-top: 30px; }
.mt-sml-40 { margin-top: 40px; }
.mt-sml-50 { margin-top: 50px; }
.mt-sml-60 { margin-top: 60px; }
.mt-sml-70 { margin-top: 70px; }
.mt-sml-80 { margin-top: 80px; }
.mt-sml-90 { margin-top: 90px; }
.mt-sml-100 { margin-top: 100px; }
.mt-sml-110 { margin-top: 110px; }
.mt-sml-120 { margin-top: 120px; }
.mt-sml-130 { margin-top: 130px; }
.mt-sml-140 { margin-top: 140px; }
.mb-sml-0 { margin-bottom: 0!important; }
.mb-sml-10 { margin-bottom: 10px; }
.mb-sml-20 { margin-bottom: 20px; }
.mb-sml-30 { margin-bottom: 30px; }
.mb-sml-40 { margin-bottom: 40px; }
.mb-sml-50 { margin-bottom: 50px; }
.mb-sml-60 { margin-bottom: 60px; }
.mb-sml-70 { margin-bottom: 70px; }
.mb-sml-80 { margin-bottom: 80px; }
.mb-sml-90 { margin-bottom: 90px; }
.mb-sml-100 { margin-bottom: 100px; }
.mb-sml-110 { margin-bottom: 110px; }
.mb-sml-120 { margin-bottom: 120px; }
.mb-sml-130 { margin-bottom: 130px; }
.mb-sml-140 { margin-bottom: 140px; }
.pt-sml-0 { padding-top: 0; }
.pt-sml-10 { padding-top: 10px; }
.pt-sml-20 { padding-top: 20px; }
.pt-sml-30 { padding-top: 30px; }
.pt-sml-40 { padding-top: 40px; }
.pt-sml-50 { padding-top: 50px; }
.pt-sml-60 { padding-top: 60px; }
.pt-sml-70 { padding-top: 70px; }
.pt-sml-80 { padding-top: 80px; }
.pt-sml-90 { padding-top: 90px; }
.pt-sml-100 { padding-top: 100px; }
.pt-sml-110 { padding-top: 110px; }
.pt-sml-120 { padding-top: 120px; }
.pt-sml-130 { padding-top: 130px; }
.pt-sml-140 { padding-top: 140px; }
.pb-sml-0 { padding-bottom: 0; }
.pb-sml-10 { padding-bottom: 10px; }
.pb-sml-20 { padding-bottom: 20px; }
.pb-sml-30 { padding-bottom: 30px; }
.pb-sml-40 { padding-bottom: 40px; }
.pb-sml-50 { padding-bottom: 50px; }
.pb-sml-60 { padding-bottom: 60px; }
.pb-sml-70 { padding-bottom: 70px; }
.pb-sml-80 { padding-bottom: 80px; }
.pb-sml-90 { padding-bottom: 90px; }
.pb-sml-100 { padding-bottom: 100px; }
.pb-sml-110 { padding-bottom: 110px; }
.pb-sml-120 { padding-bottom: 120px; }
.pb-sml-130 { padding-bottom: 130px; }
.pb-sml-140 { padding-bottom: 140px; }
}

@media only screen and (max-width:480px) {
.mt-xsm-0 { margin-top: 0!important; }
.mt-xsm-10 { margin-top: 10px; }
.mt-xsm-20 { margin-top: 20px; }
.mt-xsm-30 { margin-top: 30px; }
.mt-xsm-40 { margin-top: 40px; }
.mt-xsm-50 { margin-top: 50px; }
.mt-xsm-60 { margin-top: 60px; }
.mt-xsm-70 { margin-top: 70px; }
.mt-xsm-80 { margin-top: 80px; }
.mt-xsm-90 { margin-top: 90px; }
.mt-xsm-100 { margin-top: 100px; }
.mt-xsm-110 { margin-top: 110px; }
.mt-xsm-120 { margin-top: 120px; }
.mt-xsm-130 { margin-top: 130px; }
.mt-xsm-140 { margin-top: 140px; }
.mb-xsm-0 { margin-bottom: 0!important; }
.mb-xsm-10 { margin-bottom: 10px; }
.mb-xsm-20 { margin-bottom: 20px; }
.mb-xsm-30 { margin-bottom: 30px; }
.mb-xsm-40 { margin-bottom: 40px; }
.mb-xsm-50 { margin-bottom: 50px; }
.mb-xsm-60 { margin-bottom: 60px; }
.mb-xsm-70 { margin-bottom: 70px; }
.mb-xsm-80 { margin-bottom: 80px; }
.mb-xsm-90 { margin-bottom: 90px; }
.mb-xsm-100 { margin-bottom: 100px; }
.mb-xsm-110 { margin-bottom: 110px; }
.mb-xsm-120 { margin-bottom: 120px; }
.mb-xsm-130 { margin-bottom: 130px; }
.mb-xsm-140 { margin-bottom: 140px; }
.pt-xsm-0 { padding-top: 0; }
.pt-xsm-10 { padding-top: 10px; }
.pt-xsm-20 { padding-top: 20px; }
.pt-xsm-30 { padding-top: 30px; }
.pt-xsm-40 { padding-top: 40px; }
.pt-xsm-50 { padding-top: 50px; }
.pt-xsm-60 { padding-top: 60px; }
.pt-xsm-70 { padding-top: 70px; }
.pt-xsm-80 { padding-top: 80px; }
.pt-xsm-90 { padding-top: 90px; }
.pt-xsm-100 { padding-top: 100px; }
.pt-xsm-110 { padding-top: 110px; }
.pt-xsm-120 { padding-top: 120px; }
.pt-xsm-130 { padding-top: 130px; }
.pt-xsm-140 { padding-top: 140px; }
.pb-xsm-0 { padding-bottom: 0; }
.pb-xsm-10 { padding-bottom: 10px; }
.pb-xsm-20 { padding-bottom: 20px; }
.pb-xsm-30 { padding-bottom: 30px; }
.pb-xsm-40 { padding-bottom: 40px; }
.pb-xsm-50 { padding-bottom: 50px; }
.pb-xsm-60 { padding-bottom: 60px; }
.pb-xsm-70 { padding-bottom: 70px; }
.pb-xsm-80 { padding-bottom: 80px; }
.pb-xsm-90 { padding-bottom: 90px; }
.pb-xsm-100 { padding-bottom: 100px; }
.pb-xsm-110 { padding-bottom: 110px; }
.pb-xsm-120 { padding-bottom: 120px; }
.pb-xsm-130 { padding-bottom: 130px; }
.pb-xsm-140 { padding-bottom: 140px; }
}