.hero-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 280px 280px;
  grid-gap: 10px;
  padding-top: 40px;

  @include bp-md {
    padding-top: 120px;
    grid-template-rows: 170px 170px;
  }

  @include bp-xs {
    grid-template-columns: 1fr;
    grid-template-rows: 150px 150px 150px 150px;
  }

  &:before {
    content: none;
  }

  .hero-cell {
    padding: 0 10px;
    border: 1px #ebebeb solid;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .hero-bg {
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .hero-title {
      font-size: 32px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, .4);
      position: relative;
      color: #fff;
      font-weight: 700;
      line-height: 1;
      margin: 0;

      @include bp-md {
        font-size: 20px;
      }
    }

    .hero-subtitle {
      font-size: 16px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, .4);
      position: relative;
      margin: 0;
    }
  }
}

.header-discount {
  padding: 25px 0 20px;

  @media (max-width: 991px) {
    margin-top: 70px;
    margin-bottom: -80px;
  }
}
