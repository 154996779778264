.products-list {

  .products-widget {

  }

  .product-list-widget {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 30px;

    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    li {
      padding-top: 20px !important;

      &:last-child {
        padding-bottom: 20px !important;
        border-bottom: 1px solid #ebebeb !important;
      }
    }
  }
}
