#vue-search {

  .search-result-cell {
    max-height: calc(100vh - 10% - 80px);
    opacity: 1;
    visibility: visible;
    top: calc(10% + 80px);
    left: 50px;
    right: 50px;
    padding: 30px 80px;
    width: auto;
    min-width: 750px;
    margin: 0;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    background: #fff;
    transition: max-height .5s ease;
    box-sizing: border-box;
    overflow-y: auto;
    text-align: left;

    .product-list-widget {
      display: flex;
      flex-flow: wrap;

      li {
        padding: 15px !important;
        border: 0;
        flex: 0 0 auto;

        a {
          color: #000;
          cursor: pointer;

          &:hover {
            color: #b79d82;
          }
        }

        img {
          width: 90px;
        }

        .product-title {
          color: inherit;
          font-size: 14px;

          b {
            color: inherit;
          }
        }

        .product-ean {
          color: #878787;
          font-size: 10px;

          span {
            color: #000;
          }
        }

        .amount {
          color: inherit;
        }
      }
    }
  }
}


#mobile-search {

  .search-result-cell {
    position: fixed;
    left: 0;
    top: 115px;
    width: 100%;
    height: calc(100% - 115px);
    overflow-y: auto;
    z-index: 1;
    background: #fff;


    .product-list-widget {
      display: flex;
      flex-flow: wrap;

      li {
        padding: 15px !important;
        border: 0;
        flex: 0 0 auto;

        a {
          color: #000;
          cursor: pointer;

          &:hover {
            color: #b79d82;
          }
        }

        img {
          width: 100px;
        }

        .product-title {
          color: inherit;
          font-size: 14px;

          b {
            color: inherit;
          }
        }

        .product-ean {
          color: #878787;
          font-size: 10px;

          span {
            color: #000;
          }
        }

        .amount {
          color: inherit;
        }
      }
    }

  }
}