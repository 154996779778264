.img-hover-zoom {
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform 3s ease;
}

.img-hover-zoom:hover img {
  transform: scale(1.1);
}
