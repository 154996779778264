// Colors
$text-color: #7f7f7f;
$heading-color: #000;
$nav-color: #000;
$bg-light: #f7f8f9;
$bg-dark: #171717;
$dark-grey: #424242;
$light-grey: #a3a3a3;
$border-color: #ebebeb;
$main-color: #b79d82;


// Fonts
$body-font: 'Open Sans', sans-serif;
$heading-font: 'Montserrat', sans-serif;
$alt-font: 'Noto Serif', serif;